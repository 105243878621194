(function ($) {

  $(function () {

    $('button[history-back]').click(function () {
      history.back();
    });

    $('#cancelWithConfirm').click(function (e) {
      if (!confirm('There may be some unsaved changes which will be lost, do you want to leave anyway?'))
        e.preventDefault();
    });

  });

})(jQuery);